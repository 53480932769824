<template>
    <div>
        <el-card>
            <div slot="header">
                <span>数据详情</span>
                <el-button class="add-button" type="defautl" size="mini" @click="handleAdd">新增</el-button>
                <el-button class="import-button" type="primary" size="mini" @click="handleImport">导入</el-button>
            </div>
            <el-table :data="tableData">
                <el-table-column prop="id" label="ID">
                </el-table-column>
                <el-table-column prop="content" label="内容">
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间">
                </el-table-column>
                <el-table-column prop="updateTime" label="更新时间">
                </el-table-column>
                <el-table-column label="操作" width="150">
                    <template slot-scope="scope">
                        <el-button type="default" size="mini" @click="handleEdit(scope.row)">编辑</el-button>
                        <el-button type="danger" size="mini" @click="handleDel(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="pagination" background @size-change="handleSizeChange"
                @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size.sync="pageSize"
                :total="total" layout="total, prev, pager, next, sizes, jumper" :page-sizes="[5, 10, 20, 30, 40, 50, 100]">
            </el-pagination>
        </el-card>


        <!-- 新增/修改弹窗 -->
        <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" append-to-body>
            <el-form ref="form" :model="form" label-width="80px" @submit.native.prevent>
                <el-form-item prop="content" label="内容" required>
                    <el-input v-model="form.content"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitForm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'DataPage',
    components: {
    },
    props: {
        catalogueId: String,
    },
    data() {
        return {
            tableData: [],
            dialogVisible: false,
            dialogTitle: '',
            form: {
                id: '',
                content: '',
            },
            currentPage: 1,
            pageSize: 10,
            total: 0,
        }
    },
    mounted() {
        this.loadData();
    },
    methods: {
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.loadData();
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.loadData();
        },
        async loadData() {
            const loading = this.$loading();
            try {
                const response = await axios.get('/api/data/findAllData.php', {
                    params: {
                        catalogueId: this.catalogueId,
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                    }
                });
                console.log(response);
                if (response.data.success) {
                    this.tableData = response.data.data;
                    this.total = Number(response.data.total);
                } else {
                    this.$message.error(response.data.message);
                }
            } catch (error) {
                console.error(error);
                this.$message.error(error);
            } finally {
                loading.close();
            }
        },
        handleAdd() {
            this.dialogTitle = '新增数据';
            this.form.content = '';
            this.dialogVisible = true;
        },
        handleEdit(row) {
            this.dialogTitle = '修改数据';
            this.form.id = row.id;
            this.form.content = row.content;
            this.dialogVisible = true;
        },
        submitForm() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    if (this.dialogTitle === '新增数据') {
                        this.submitAdd();
                    } else if (this.dialogTitle === '修改数据') {
                        this.submitEdit();
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        async submitAdd() {
            const loading = this.$loading();
            try {
                const response = await axios.post('/api/data/addData.php', {
                    catalogueId: this.catalogueId,
                    contents: [this.form.content]
                });
                console.log(response);
                if (response.data.success) {
                    this.$message.success('新增成功');
                    this.loadData();
                    this.dialogVisible = false;
                } else {
                    this.$message.error(response.data.message);
                }
            } catch (error) {
                console.error(error);
                this.$message.error(error);
            } finally {
                loading.close();
            }
        },
        async submitEdit() {
            const loading = this.$loading();
            try {
                const response = await axios.get('/api/data/editData.php', {
                    params: {
                        id: this.form.id,
                        content: this.form.content
                    }
                });
                console.log(response);
                if (response.data.success) {
                    this.$message.success('修改成功');
                    this.loadData();
                    this.dialogVisible = false;
                } else {
                    this.$message.error(response.data.message);
                }
            } catch (error) {
                console.error(error);
                this.$message.error(error);
            } finally {
                loading.close();
            }
        },
        handleDel(row) {
            this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const loading = this.$loading();
                try {
                    const response = await axios.get('/api/data/delData.php', {
                        params: {
                            id: row.id
                        }
                    });
                    console.log(response);
                    if (response.data.success) {
                        this.$message.success('删除成功');
                        this.loadData();
                    } else {
                        this.$message.error(response.data.message);
                    }
                } catch (error) {
                    console.error(error);
                    this.$message.error(error);
                } finally {
                    loading.close();
                }
            }).catch(() => {
                // this.$message.info('已取消删除');
            });
        },

        handleImport() {
            // Open file dialog to select a txt file
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = '.txt';
            input.addEventListener('change', async (event) => {
                const file = event.target.files[0];
                const reader = new FileReader();
                reader.onload = async (e) => {
                    const contents = e.target.result;
                    let dataArray = contents.split('\n');
                    dataArray = dataArray.map((item) => {
                        return item.trim();
                    }).filter((item) => {
                        return item !== '';
                    });

                    const loading = this.$loading();
                    try {
                        const response = await axios.post('/api/data/addData.php', {
                            catalogueId: this.catalogueId,
                            contents: dataArray
                        });
                        console.log(response);
                        if (response.data.success) {
                            this.$message.success('导入成功');
                            this.loadData();
                        } else {
                            this.$message.error(response.data.message);
                        }
                    } catch (error) {
                        console.error(error);
                        this.$message.error(error);
                    } finally {
                        loading.close();
                    }
                };
                reader.readAsText(file);
            });
            input.click();
        },
    }
}
</script>

<style scoped>
.add-button {
    float: right;
}

.import-button {
    float: right;
    margin-right: 10px;
}

.pagination {
    margin-top: 20px;
    text-align: right;
}
</style>