<template>
    <div>
        <el-container>
            <el-main>
                <el-card>
                    <div slot="header">
                        <span>目录</span>
                        <el-button class="add-button" type="primary" size="mini" @click="handleAdd">新增</el-button>
                    </div>
                    <el-table :data="tableData">
                        <el-table-column prop="id" label="ID" width="50">
                        </el-table-column>
                        <el-table-column prop="name" label="目录名">
                        </el-table-column>
                        <el-table-column prop="createTime" label="创建时间">
                        </el-table-column>
                        <el-table-column prop="updateTime" label="更新时间">
                        </el-table-column>
                        <!-- <el-table-column prop="dataCount" label="数据量">
                        </el-table-column> -->
                        <el-table-column label="操作" width="280">
                            <template slot-scope="scope">
                                <el-button type="primary" size="mini" @click="handleDetails(scope.row)">查看</el-button>
                                <el-button type="default" size="mini" @click="handleEdit(scope.row)">编辑</el-button>
                                <el-button type="warning" size="mini" @click="handleClear(scope.row)">清空</el-button>
                                <el-button type="danger" size="mini" @click="handleDel(scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination class="pagination" background @size-change="handleSizeChange"
                        @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size.sync="pageSize"
                        :total="total" layout="total, prev, pager, next, sizes, jumper"
                        :page-sizes="[5, 10, 20, 30, 40, 50, 100]">
                    </el-pagination>
                </el-card>
            </el-main>
        </el-container>

        <!-- 新增/修改弹窗 -->
        <el-dialog :title="dialogTitle" :visible.sync="dialogVisible">
            <el-form ref="form" :model="form" label-width="80px" @submit.native.prevent>
                <el-form-item prop="name" label="目录名" required>
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitForm">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 查看目录下的数据列表的弹窗 -->
        <el-dialog :title="dialogTitle_details" :visible.sync="dialogVisible_details" width="90%">
            <DataPage v-if="dialogVisible_details" :catalogueId="detailsCatalogueId" />
        </el-dialog>

    </div>
</template>

<script>
import axios from 'axios';
import DataPage from "./DataPage.vue";

export default {
    name: 'HomePage',
    components: {
        DataPage,
    },
    data() {
        return {
            tableData: [],
            dialogVisible: false,
            dialogTitle: '',
            form: {
                id: '',
                name: '',
            },
            dialogVisible_details: false,
            dialogTitle_details: '',
            detailsCatalogueId: '',
            currentPage: 1,
            pageSize: 10,
            total: 0,
        }
    },
    mounted() {
        this.loadData();
    },
    methods: {
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.loadData();
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.loadData();
        },
        async loadData() {
            const loading = this.$loading();
            try {
                const response = await axios.get('/api/catalogue/findAllCatalogue.php', {
                    params: {
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                    }
                });
                console.log(response);
                if (response.data.success) {
                    this.tableData = response.data.data;
                    this.total = Number(response.data.total);
                } else {
                    this.$message.error(response.data.message);
                }
            } catch (error) {
                console.error(error);
                this.$message.error(error);
            } finally {
                loading.close();
            }
        },
        handleAdd() {
            this.dialogTitle = '新增目录';
            this.form.name = '';
            this.dialogVisible = true;
        },
        handleEdit(row) {
            this.dialogTitle = '修改目录';
            this.form.id = row.id;
            this.form.name = row.name;
            this.dialogVisible = true;
        },
        submitForm() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    if (this.dialogTitle === '新增目录') {
                        this.submitAdd();
                    } else if (this.dialogTitle === '修改目录') {
                        this.submitEdit();
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        async submitAdd() {
            const loading = this.$loading();
            try {
                const response = await axios.get('/api/catalogue/addCatalogue.php', {
                    params: {
                        name: this.form.name
                    }
                });
                console.log(response);
                if (response.data.success) {
                    this.$message.success('新增成功');
                    this.loadData();
                    this.dialogVisible = false;
                } else {
                    this.$message.error(response.data.message);
                }
            } catch (error) {
                console.error(error);
                this.$message.error(error);
            } finally {
                loading.close();
            }
        },
        async submitEdit() {
            const loading = this.$loading();
            try {
                const response = await axios.get('/api/catalogue/editCatalogue.php', {
                    params: {
                        id: this.form.id,
                        name: this.form.name
                    }
                });
                console.log(response);
                if (response.data.success) {
                    this.$message.success('修改成功');
                    this.loadData();
                    this.dialogVisible = false;
                } else {
                    this.$message.error(response.data.message);
                }
            } catch (error) {
                console.error(error);
                this.$message.error(error);
            } finally {
                loading.close();
            }
        },
        handleDel(row) {
            this.$confirm('此操作将删除该目录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const loading = this.$loading();
                try {
                    const response = await axios.get('/api/catalogue/delCatalogue.php', {
                        params: {
                            id: row.id
                        }
                    });
                    console.log(response);
                    if (response.data.success) {
                        this.$message.success('删除成功');
                        this.loadData();
                    } else {
                        this.$message.error(response.data.message);
                    }
                } catch (error) {
                    console.error(error);
                    this.$message.error(error);
                } finally {
                    loading.close();
                }
            }).catch(() => {
                // this.$message.info('已取消删除');
            });
        },
        handleDetails(row) {
            this.dialogTitle_details = '目录：' + row.name;
            this.detailsCatalogueId = row.id;
            this.dialogVisible_details = true;
        },
        handleClear(row) {
            this.$confirm('此操作将清空该目录下的所有数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const loading = this.$loading();
                try {
                    const response = await axios.get('/api/data/delAllData.php', {
                        params: {
                            catalogueId: row.id
                        }
                    });
                    console.log(response);
                    if (response.data.success) {
                        this.$message.success('清空成功');
                        this.loadData();
                    } else {
                        this.$message.error(response.data.message);
                    }
                } catch (error) {
                    console.error(error);
                    this.$message.error(error);
                } finally {
                    loading.close();
                }
            }).catch(() => {
                // this.$message.info('已取消清空');
            });
        },
    }
}
</script>

<style scoped>
.add-button {
    float: right;
}

.pagination {
    margin-top: 20px;
    text-align: right;
}
</style>